<template>
  <!-- 修改快递单号弹框 -->
  <a-modal
    title="新建 / 编辑快递单号"
    width="40%"
    :visible="modalVisible"
    destroy-on-close
    okText="确定"
    cancelText="取消"
    @ok="ok"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  > 
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 10 }"
  >
    <a-form-model-item v-if="isObj" label="快递公司" prop="trackingName">
      <a-input class="input" v-model="form.trackingName" />
    </a-form-model-item>
    <a-form-model-item label="快递单号" prop="trackingNumber">
      <a-input class="input" v-model="form.trackingNumber" />
    </a-form-model-item>
  </a-form-model>
  </a-modal>
</template>

<script>
export default {
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    expressVal: {
      type: String,
      default: "",
    },
    trackingName: {
      type: String,
      default: ''
    },
    isObj: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    expressVal(newVal) {
      this.form.trackingNumber = newVal
      this.form.trackingName = this.trackingName
    },
  },
  data() {
    return {
      form: {
        trackingNumber: '',
        trackingName: ''
      },
      rules: {
        trackingNumber: [{ required: true, message: '请输入快递单号', trigger: 'blur' }],
        trackingName: [{ required: true, message: '请输入快递公司', trigger: 'blur' }],
      }
    }
  },
  methods: {
    ok() {
      if (this.isObj) {
        this.$refs.ruleForm.validate(valid => {
          if (!valid) return
          this.$emit("ok", this.form)
        })
      } else {
        this.$emit("ok", this.form.trackingNumber)
        this.form.trackingNumber = ''
      }
    }
  }
}
</script>
 
<style lang="less" scoped>
.input {
  width: 400px;
  margin-left: 20px;
}
</style>