<template>
  <!-- 第三方信息弹框 -->
  <a-modal
    title="新建 / 编辑第三方信息"
    width="40%"
    :visible="modalVisible"
    okText="确定"
    cancelText="取消"
    @ok="ok"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  >
    <div class="line">
      <span>第三方订单号:</span>
      <a-input v-model="obj.thirdPartyOrderNo"></a-input>
    </div>
    <div class="line">
      <span>第三方收件人手机号:</span>
      <a-input v-model="obj.thirdPartyPhone"></a-input>
    </div>
    <div class="line">
      <span>第三方渠道:</span>
      <a-select
        v-model="obj.thirdPartyChannel"
        style="width: 120px"
        :getPopupContainer="
          (triggerNode) => triggerNode.parentNode || document.body
        "
        @change="selectChange"
      >
        <a-select-option v-for="item in thirdPartyChannelArr" :key="item.key">
          {{ item.value }}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
export default {
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.thirdPartyChannel.forEach((item) =>
      this.thirdPartyChannelArr.push(item)
    );
    this.obj = this.form;
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      obj: {
        thirdPartyOrderNo: "",
        thirdPartyPhone: "",
        thirdPartyChannel: "",
      },
      thirdPartyChannelArr: [{ key: 0, value: "请选择" }],
    };
  },
  methods: {
    selectChange(value) {
      this.obj.thirdPartyChannel = value;
    },
    ok() {
      this.$emit("ok", this.obj);
    },
  },
};
</script>
 
<style lang="less" scoped>
.line {
  margin-bottom: 20px;
  span {
    display: inline-block;
    width: 130px;
    margin-right: 20px;
  }
  .ant-input {
    width: 400px;
  }
}
</style>